<template lang="pug">

	.blends

		section.blends__section.blends__section--main

			img.blends__img.blends__img--main(src="~@/assets/img/blends/blends_main.jpg")

			.blends__container(class="container u-h100")

				.blends__content

					h1.blends__title.blends__title--top(class="primary-title") Color Blends

					p.blends__sub-title(class="sub-title") Create your own flooring designs from Titus Surface

					button.blends__button.blends__button--main(class="btn btn--default" @click="$router.push('/color-mixer')") Try colour mixer


		section.blends__section.blends__section--examples

			.blends__container.blends__container--examples(class="container u-df-row-wrap-start")


				.blends__example(v-for="(item, index) in items" :key="index")
					img.blends__example-image(:src="item.image")
					h3.blends__example-title(class="article-title") {{ item.title }}
					ul.blends__example-parts
						li.blends__example-parts-item(v-for="(part, innerIndex) in item.parts" :key="innerIndex") {{ part }}


		section.blends__section.blends__section--bottom

			.blends__container.blends__container--bottom(class="container")


				.blends__content.blends__content--bottom

					h1.blends__title.blends__title--bottom(class="primary-title") Create your own blend with the colour mixer

					button.blends__button.blends__button--bottom(class="btn btn--blue" @click="$router.push('/color-mixer')") Go to colour mixer



</template>

<script>
export default {
	name: 'ColorBlendsView',

	data() {
		return {
			items: [
				{
					image: require('@/assets/img/blends/img_gold.jpg'),
					title: 'Gold',
					parts: [
						'1 part RH40 Mustard',
						'2 parts RH41 Bright Yellow'
					]
				},
				{
					image: require('@/assets/img/blends/img_golden_sunshine.jpg'),
					title: 'Golden Sunshine',
					parts: [
						'1 Part RH40 Mustard',
						'1 Part RH41 Bright Yellow',
						'1 Part RH50 Orange'
					]
				},
				{
					image: require('@/assets/img/blends/img_saturn_red.jpg'),
					title: 'Saturn Red',
					parts: [
						'1 Part RH02 Bright Red',
						'1 Part RH41 Bright Yellow',
						'1 Part RH50 Orange'
					]
				},
				{
					image: require('@/assets/img/blends/img_autumn_gold.jpg'),
					title: 'Autumn Gold',
					parts: [
						'1 Part RH02 Bright Red',
						'1 Part RH32 Brown',
						'1 Part RH40 Mustard'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/img_apricot_orange.jpg'),
					title: 'Apricot Orange',
					parts: [
						'1 Part RH30 Beige',
						'1 Part RH50 Orange'
					]
				},
				{
					image: require('@/assets/img/blends/img_dark_orange.jpg'),
					title: 'Dark Orange',
					parts: [
						'1 Part RH02 Bright Red',
						'1 Part RH41 Bright Yellow'
					]
				},
				{
					image: require('@/assets/img/blends/img_yellow_ochre.jpg'),
					title: 'Yellow Ochre',
					parts: [
						'1 Part RH32 Brown',
						'1 Part RH40 Mustard'
					]
				},
				{
					image: require('@/assets/img/blends/img_autumn_brown.jpg'),
					title: 'Autumn Brown',
					parts: [
						'2 Parts RH01 Standard Red',
						'2 Parts RH33 Dark Brown',
						'1 Part RH40 Mustard'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/img_brown_ochre.jpg'),
					title: 'Brown Ochre',
					parts: [
						'2 Parts RH33 Dark Brown',
						'1 Part RH40 Mustard'
					]
				},
				{
					image: require('@/assets/img/blends/img_spring_green.jpg'),
					title: 'Spring Green',
					parts: [
						'1 Part RH11 Bright Green',
						'1 Part RH12 Dark Green',
						'1 Part RH41 Bright Yellow'
					]
				},
				{
					image: require('@/assets/img/blends/img_greens.jpg'),
					title: 'Greens',
					parts: [
						'1 Part RH10 Standard Green',
						'1 Part RH12 Dark Green'
					]
				},
				{
					image: require('@/assets/img/blends/img_green_meadow.jpg'),
					title: 'Green Meadow',
					parts: [
						'1 Part RH10 Standard Green',
						'1 Part RH11 Bright Green',
						'1 Part RH12 Dark Green'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/img_vivid_green.jpg'),
					title: 'Vivid Green',
					parts: [
						'2 Part RH11 Bright Green',
						'1 Part RH12 Dark Green'
					]
				},
				{
					image: require('@/assets/img/blends/img_forest_green.jpg'),
					title: 'Forest Green',
					parts: [
						'1 Part RH10 Standard Green',
						'1 Part RH12 Dark Green',
						'1 Part RH21 Purple'
					]
				},
				{
					image: require('@/assets/img/blends/img_ultramarine.jpg'),
					title: 'Ultramarine',
					parts: [
						'1 Part RH20 Standard Blue',
						'1 Part RH21 Purple'
					]
				},
				{
					image: require('@/assets/img/blends/img_mid_blue.jpg'),
					title: 'Mid Blue',
					parts: [
						'1 Part RH22 Light Blue',
						'1 Part RH31 Cream'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/Img_Ice-Blue.jpg'),
					title: 'Ice Blue',
					parts: [
						'1 Part RH23 Azure',
						'1 Part RH26 Turquoise',
						'2 Parts RH31 Cream'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Aqua-Blue.jpg'),
					title: 'Aqua Blue',
					parts: [
						'1 Part RH23 Azure',
						'1 Part RH26 Turquoise'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Bubblegum.jpg'),
					title: 'Bubblegum',
					parts: [
						'1 Part RH02 Bright Red',
						'1 Part RH26 Turquoise',
						'1 Part RH90 Funky Pink'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Cherry-Red.jpg'),
					title: 'Cherry Red',
					parts: [
						'1 Part RH02 Bright Red',
						'1 Part RH90 Funky Pink'
					]
				},
				//

				//				
				{
					image: require('@/assets/img/blends/Img_Funky-Purple.jpg'),
					title: 'Funky Purple',
					parts: [
						'3 Part RH21 Purple',
						'1 Part RH90 Funky Pink'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Grey-Turquoise.jpg'),
					title: 'Grey Turquoise',
					parts: [
						'1 Part RH26 Turquoise',
						'1 Part RH61 Light Grey'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Electric-Grey.jpg'),
					title: 'Electric Grey',
					parts: [
						'1 Part RH23 Azure',
						'1 Part RH60 Dark Grey'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Hacienda-Grey.jpg'),
					title: 'Hacienda Grey',
					parts: [
						'1 Part RH41 Bright Yellow',
						'1 Part RH60 Dark Grey',
						'1 Part RH65 Pale Grey'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/Img_Granite.jpg'),
					title: 'Granite',
					parts: [
						'1 Part RH60 Dark Grey',
						'1 Part RH65 Pale Grey'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Desert-Camouflage.jpg'),
					title: 'Desert Camouflage',
					parts: [
						'1 Part RH30 Beige',
						'1 Part RH31 Cream'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Desert-Camouflage-Mid.jpg'),
					title: 'Desert Camouflage Mid',
					parts: [
						'1 Part RH30 Beige',
						'1 Part RH31 Cream',
						'1 Part RH32 Light Brown'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Desert-Camouflage-Dark.jpg'),
					title: 'Desert Camouflage Dark',
					parts: [
						'1 Part RH30 Beige',
						'1 Part RH31 Cream',
						'1 Part RH32 Light Brown',
						'1 Part RH33 Dark Brown'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/Img_Sand.jpg'),
					title: 'Sand',
					parts: [
						'1 Part RH30 Beige',
						'1 Part RH40 Mustard',
						'1 Part RH41 Bright Yellow'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Sand-Mid.jpg'),
					title: 'Sand Mid',
					parts: [
						'1 Part RH30 Beige',
						'1 Part RH31 Cream',
						'1 Part RH40 Mustard',
						'1 Part RH41 Bright Yellow'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Midnight-Blue.jpg'),
					title: 'Midnight Blue',
					parts: [
						'1 Part RH20 Standard Blue',
						'1 Part RH21 Purple',
						'2 Parts RH70 Black'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Midnight-Purple.jpg'),
					title: 'Midnight Purple',
					parts: [
						'1 Part RH21 Purple',
						'2 Parts RH70 Black'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/Img_Bonfire-Black.jpg'),
					title: 'Bonfire Black',
					parts: [
						'1 Part RH02 Bright Red',
						'1 Part RH70 Black'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Glowing-Embers.jpg'),
					title: 'Glowing Embers',
					parts: [
						'1 Part RH02 Bright Red',
						'1 Part RH50 Orange',
						'2 Parts RH70 Black'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Pumpkin-Orange.jpg'),
					title: 'Pumpkin Orange',
					parts: [
						'1 Part RH40 Mustard',
						'2 Parts RH50 Orange'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Sunset-Pink.jpg'),
					title: 'Sunset Pink',
					parts: [
						'2 Parts RH02 Bright Red',
						'1 Part RH50 Orange',
						'1 Part RH90 Funky Pink'
					]
				},
				//

				//
				{
					image: require('@/assets/img/blends/Img_Verdigris.jpg'),
					title: 'Verdigris',
					parts: [
						'2 Parts RH11 Bright Green',
						'1 PartRH23 Azure'
					]
				},
				{
					image: require('@/assets/img/blends/Img_Wicked-Green.jpg'),
					title: 'Wicked Green',
					parts: [
						'2 Parts RH11 Bright Green',
						'1 Part RH23 Azure',
						'1 Part RH41 Bright Yellow'
					]
				}
			]

		}
	}

}
</script>

<style scoped lang="sass">
@import '~@/assets/styles/abstracts/_variables.sass'


.blends
	
	
	&__section


		&--main
			min-height: 67vh
			position: relative


			@media screen and (max-width: $phone)
				min-height: 51rem


		&--examples
			padding-top: 14rem


			@media screen and (max-width: $phone)
				padding-top: 6rem


		&--bottom
			background: url(~@/assets/img/blends/blends_bottom.jpg) no-repeat center / cover
			height: 100vh

			margin-bottom: -12rem


			@media screen and (max-width: $phone)
				height: 75rem

				position: relative

				bottom: -2rem
					
	
	&__img


		&--main
			object-fit: contain
			object-position: top

			position: absolute
			
			top: 0
			right: 0
			
			height: 93rem

			max-height: 95vh
			max-width: 60%

			z-index: -1


			@media screen and (max-width: $phone)
				max-height: 31rem
				max-width: 99.5%


	&__container


		&--examples


			@media screen and (max-width: 1590px)
				width: 1071px


			@media screen and (max-width: $small_desktop)
				width: 90%
				justify-content: center


			@media screen and (max-width: $phone)
				width: 100%

				flex-direction: column
				align-items: center


		&--bottom
			position: relative


			@media screen and (max-width: $phone)
				text-align: center


	&__content
		position: absolute

		top: 33vh


		@media screen and (max-width: $phone)
			width: 89%
			top: 27rem

			text-align: center


		&--bottom
			top: 39vh


			@media screen and (max-width: $phone)
				top: 25rem


	&__title


		@media screen and (max-width: $tablet)
			width: 100% !important


		&--top
			width: 57.9rem
			margin-bottom: 1.8rem


		&--bottom
			width: 76.1rem
			margin-bottom: 8.1rem


	&__button
		display: block

		width: 26.3rem


		&--main
			margin-top: 10rem


			@media screen and (max-width: $phone)
				margin-top: 5rem
				display: inline-block


		&--bottom


			@media screen and (max-width: $phone)
				display: inline-block


	&__example
		flex-basis: 25%
		max-width: 31rem
		margin-bottom: 16rem


		@media screen and (max-width: $phone)
			flex-basis: unset

			max-width: 75%
			margin-right: 0 !important
			margin-bottom: 7rem

		
		&:not(:nth-child(4n))
			margin-right: 5.3rem


			@media screen and (max-width: 1590px)
				margin-right: unset

		
		&:not(:nth-child(3n))


			@media screen and (max-width: 1590px)
				margin-right: 7.8rem


		&-image


			@media screen and (max-width: $phone)
				width: 100%


		&-title
			margin: 2.2rem 0 1.8rem
			padding: 0 2.2rem


			@media screen and (max-width: $phone)
				padding: 0


		&-parts
			padding: 0 2.2rem


			@media screen and (max-width: $phone)
				padding: 0


			&-item
				display: block
				line-height: 2.1rem
				

</style>